var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, } from "@mui/material";
import "survey-core/defaultV2.min.css";
import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";
import "survey-creator-core/survey-creator-core.min.css";
import { setLicenseKey } from "survey-core";
import { useCallback, useMemo, useState } from "react";
import { createNPS } from "./apis/nps";
setLicenseKey("NTIwMDBjNWMtODk5My00YzE1LWExNWItYmEyY2E0OTFjZjdjOzE9MjAyNS0xMi0xMiwyPTIwMjUtMTItMTIsND0yMDI1LTEyLTEy");
export var App = function () {
    var creator = useMemo(function () {
        return new SurveyCreator({
            showLogicTab: true,
            isAutoSave: true,
        });
    }, []);
    var handleChangeUrlApi = useCallback(function (event) {
        setUrlApi(event.target.value);
    }, []);
    var handleChangeTituloForm = useCallback(function (event) {
        setTituloFormulario(event.target.value);
    }, []);
    var _a = useState(""), urlApi = _a[0], setUrlApi = _a[1];
    var _b = useState(""), tituloFormulario = _b[0], setTituloFormulario = _b[1];
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    var _d = useState(null), formId = _d[0], setFormId = _d[1];
    var buttonNewNPS = useCallback(function () {
        var NovoNPS = {
            titulo: tituloFormulario,
            descricao: "Formulário criado pelo Designer",
            json_data: creator.text,
            api: urlApi,
        };
        createNPS(NovoNPS)
            .then(function (response) {
            setFormId(response.id);
            setOpen(true);
        })
            .catch(function (err) {
            console.log(err);
        });
    }, [tituloFormulario, creator.text, urlApi]);
    var handleClose = function () {
        setOpen(false);
        window.location.reload();
    };
    return (_jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx("h1", { children: "NPS Designer" }) })), _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(TextField, { label: "T\u00EDtulo do Formul\u00E1rio", variant: "outlined", fullWidth: true, value: tituloFormulario, onInput: handleChangeTituloForm }) })), _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(TextField, { label: "URL da API", variant: "outlined", fullWidth: true, value: urlApi, onInput: handleChangeUrlApi }) })), _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(SurveyCreatorComponent, { creator: creator, style: { designerHeight: "2000px" } }) })), _jsxs(Grid, __assign({ item: true, xs: 12 }, { children: [_jsxs(Dialog, __assign({ open: open, onClose: handleClose }, { children: [_jsx(DialogTitle, { children: "Formul\u00E1rio Criado" }), _jsxs(DialogContent, { children: ["O id do formul\u00E1rio criado \u00E9: ", formId] }), _jsx(DialogActions, { children: _jsx(Button, __assign({ onClick: handleClose, color: "primary" }, { children: "Fechar" })) })] })), _jsx(Button, __assign({ variant: "contained", color: "primary", onClick: buttonNewNPS }, { children: "Salvar Formul\u00E1rio" }))] }))] })));
};
